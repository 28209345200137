<template>
    <div  class="table" ref="tableContainer" style="contain: content; overflow-x: scroll ;">
        <div :style="{ width: tableWidth }" class="table-container">
            <div :style="{ width: '100%' }" v-if="data.length > 0" class="pl-1">
                <v-virtual-scroll v-if="data.length > 0" :bench="15" :items="data" :max-height="calculatedHeight" min-height="27.5" style="height: auto;" :width="'100%'" item-height="29" class="px-1">
                    <template v-slot:default="{ index, item }">
                        <div  class="text-center my-0 py-0" :style="{display: 'flex','flex-direction': 'row',width: '100%','background-color' : selectedPallets.includes(item.id) ? 'rgba(114, 159, 217, 0.2)' : Math.abs(index % 2) == 1 ? '' : 'var(--v-greyDarker-base)' ,cursor: 'pointer'}">
                            <td v-for="(header, index) in headers.filter(x => x.show)" :key="index" :style="{ width: header.width ?? '150px', 'font-size': '11px', }" 
                                class="text-center mt-0 mb-0 pb-0 tableCell" @contextmenu="viewItem($event, item, header.value)" @click="addToFilter(item[header.value], header.value, header.disableFilter)">
                                <div v-if="header.value == 'actions'" class="tex†-center" >
                                    <v-btn @click="selectPallet(item)" small v-if="itemSelected(item.id)" style="border: none" color="success" icon >
                                      <v-icon small>check</v-icon>
                                    </v-btn>
                                    <v-btn small @click="selectPallet(item)" v-else icon style="border: 0.25px dotted grey" color="grey" >
                                      <v-icon small>check</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else-if="header.value == 'status'" class="tex†-center" >
                                  <v-chip v-if="item[header.value]" small outlined :color="getPalletStatusColor(item[header.value]) " style="font-size: 11px" >
                                    {{ item[header.value] }}
                                  </v-chip>
                                </div>
                                <div v-else-if="header.value == 'palletQuantity'" class="text-center">
                                  {{ Math.round((parseFloat(item[header.value]) + Number.EPSILON) * 100) / 100 }}
                                </div>
                                <div v-else-if="header.value == 'targetCountry'" class="text-center">
                                  <v-avatar size="24" v-if="item.targetCountry">
                                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.targetCountry.substring(0,2).toLowerCase()}.svg`"></v-img>
                                  </v-avatar>
                                  {{ item[header.value] }}
                                </div>
                                <div v-else-if="header.value == 'country'" class="text-center">
                                  <v-avatar size="24" v-if="item.country">
                                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.country.substring(0,2).toLowerCase()}.svg`"></v-img>
                                  </v-avatar>
                                  {{ item[header.value] }}
                                </div>
                                <div v-else-if="header.value == 'additional'" class="text-center">  
                                  <v-tooltip top v-if="item.permitDocument">
                                    <template v-slot:activator="{ on }">
                                      <v-btn class="mx-1" outlined v-on="on" small icon>
                                        <v-icon small>agriculture</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Removal Permit: {{ item.permitNo }} expires
                                      {{ item.permitExpiryDate }}</span>
                                  </v-tooltip>
                                
                                  <v-tooltip top v-if="checkMetadata('Titan Ready', item.palletMetadata)">
                                    <template v-slot:activator="{ on }">
                                      <v-chip color="grey --lighten-3" small class="mx-1" v-on="on" @click="viewTitan(item)"
                                       >
                                        <v-icon v-if="verify('Titan Ready', item.palletMetadata)" small left color="black">check</v-icon>
                                        <v-icon v-else color="red" left small>close</v-icon>
                                        <v-img contain style="width: 35px" rel="preload"
                                                src="../../../assets/ppecblogo.png"></v-img>
                                      </v-chip>
                                    </template>
                                    <span>Titan Check:
                                      {{ metadataValue("Titan Ready", item.palletMetadata) }}</span>
                                  </v-tooltip>
                                
                                  <v-tooltip top v-if="checkMetadata('Phytclean Verified', item.palletMetadata)">
                                    <template v-slot:activator="{ on }">
                                      <v-chip small class="mx-1" v-on="on" @click="viewPhytoCertificate(item)"
                                       >
                                        <v-icon v-if="verify('Phytclean Verified', item.palletMetadata)" small left color="black">check</v-icon>
                                        <v-icon v-else color="red" left small>close</v-icon>
                                        <v-img contain style="width: 35px" rel="preload"
                                                src="../../../assets/PhytClean.png"></v-img>
                                      </v-chip>
                                    </template>
                                    <span>Phytclean verification:
                                      {{
                                        metadataValue("Phytclean Verified", item.palletMetadata)
                                      }}</span>
                                  </v-tooltip>

                                </div>
                                <div v-else class="text-center">
                                  <v-chip v-if="item[header.value]" small class="primaryText--text" outlined style="border: none; font-size: 11px" >
                                    {{ item[header.value] }}
                                  </v-chip>
                                </div>                
                            </td>
                        </div>
                    </template>
                </v-virtual-scroll>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        data: {
            type: Array,
            required: false
        },
        headers: {
            type: Array,
            required: true
        },
        sortCriteria: {
            type: Array,
            required: false
        },
        selectedPallets: {
            type: Array,
            required: false
        }
    },
    data: () => ({
        sortBy: 'id',
        sortDesc: false,
        x: 0,
        y: 0,
        showMenu: false,
        columnDrag: false,
        searchKey: 0,
        palletStatus: [
          { name: "In Stock", icon: "local_shipping", color: "orange" },
          { name: "Allocated", icon: "fact_check", color: "cyan" },
          { name: "In Transit", icon: "local_shipping", color: "teal" },
          { name: "Rejected", icon: "verified", color: "orange" },
          { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
          { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
          { name: "Cancelled", icon: "cancel", color: "red" },
          { name: "Removed", icon: "cancel", color: "grey" },
          { name: "Instruction Sent", icon: "email", color: "indigo" },
        ],
        filters: {},
        files: [],
        searchHeaders: {},
        headerClicked: null,
        selectedContainer: null,
        selectAllCheck: false,
        childHeaders: [],
        resizing: false,
        initialX: 0,
        initialWidth: 0,
        allocated: [],
        currentIndex: null,
        minWidth: 50,
    }),
    watch: {
        headers: {
            immediate: true,
            handler(val) {
                this.setHeaders();
            }
        },
    },
    computed: {
        tableWidth() {
            let result = 0;
            let childHeaders = []
            if(Array.isArray(this.childHeaders)) {
              childHeaders = this.childHeaders
            } else if(typeof this.childHeaders === 'string') {
              childHeaders = JSON.parse(this.childHeaders)
            } else {
              this.$message.error('An Error occurred. Please refresh the page. If this error persists, please log a ticket with our Development team.')
            }
            this.childHeaders.filter(x => x.show).forEach((x) => { result += parseInt(x.width ? x.width.replace("px", "") : 150)})
            return result + "px";
        },
        calculatedHeight() {
            let height = window.innerHeight;
            return height * 0.80 + "px";
        },
    },
    methods: {
      addToFilter(value, header, disableFilter) {
        if(!disableFilter) {
          this.$emit('addToFilter', value, header)
        }
      },
      metadataValue(type, metadata) {
        let find = metadata.find((x) => x.key == type);
        return find.value;
      },
      checkMetadata(type, metadata) {
        let find = metadata.find((x) => x.key == type);
        return find ? true : false;
      },
      verify(type, metadata) {
        let find = metadata.find((x) => x.key == type);
        return find ? find.value == "Passed" : false;
      },
        getPalletStatusColor(status) {
          let find = this.palletStatus.find((x) => x.name == status);
          return find ? find.color : "grey";
        },
        selectPallet(item) {
          this.$emit('updateSelectedPallets', item)
        },
        statusColor(status) {
          if (status) {
            let color = this.orderStatus.find(
              (x) => x.name.toLowerCase() === status.toLowerCase()
            );
            return color ? color.color : "grey";
          } else return "grey";
        },
        statusIcon(status) {
          if (status) {
            let find = this.orderStatus.find(
              (x) => x.name.toLowerCase() === status.toLowerCase()
            );
            return find ? find.icon : "";
          } else return "help";
        },
        async setActiveContainer(container) {
          this.selectedContainer = container;
          this.loadOutKey++;
          await this.loadRelatedStock();
        },
        async updatePalletStatus(status) {
          try {
            this.loadingStatus = true;
            await this.$API.updateStockPalletStatus(this.selectedPallets, status);
            this.selectedContainer.status = status;
            let filtered = this.stock.filter((x) =>
              this.selectedPallets.includes(x.id)
            );
            filtered.forEach((x) => {
              x.status = status;
            });
            this.loadingStatus = false;
            this.updateStatusModal = false;
            this.$message.success("Successfully updated pallet status!");
          } catch (e) {
            console.log(e);
            this.loadingStatus = false;
          }
        },
        updateSearchKey() {
          this.searchKey++;
        },
        itemSelected(id) {
          let result = this.selectedPallets.includes(id);
          return result;
        },
        isSorted(value) {
          if(this.sortCriteria.find(x => x.value == value)) {
            let find = this.sortCriteria.find(x => x.value == value);
            let index = this.sortCriteria.findIndex(x => x.value == value);
            let obj = {
              value: find.value,
              order: find.order,
              index: index
            }
            return obj;
          } else {
            return false
          }
        },
        viewItem(e, item, header) {
          e.preventDefault()
        },
        copyContent() {
          let text = this.selectedContainer[this.headerClicked]
          navigator.clipboard.writeText(text)
          this.$message.success('Copied to clipboard')
        },
        viewShipment(item, type) {
            if(['@dotparse', '@loglive'].some(sub => this.$store.state.user.emailAddress.includes(sub))) {
              if(item.shipmentReference || item.systemReference) {
                sessionStorage.setItem('tradingItem', JSON.stringify(item));
                type ? sessionStorage.setItem('tradingType', type) : sessionStorage.setItem('tradingType', 'shipment'); 
                if(type === 'container') {
                  sessionStorage.setItem('tradingType', 'order')
                  let containerId = this.selectedContainer.id
                  let obj = {
                    tab: 'overview',
                    shortcut: 'container',
                    id: containerId
                  }
                  sessionStorage.setItem('TradingShortcut', JSON.stringify(obj))
                } else {
                  sessionStorage.removeItem('TradingShortcut')
                }
                this.$router.push({
                path: "/trading/summary/" + (type === 'shipment' ? item.shipmentReference : item.systemReference),
              });
            } else {
              this.$message.error('No Shipment Reference')
            }
          } else {
            console.log('This feature is not yet live')
          }
        },
        initiateResize(event, index) {
            this.resizing = true;
            this.initialX = event.clientX;
            this.currentIndex = index;
            const element = event.target.parentElement;
            const computedStyle = window.getComputedStyle(element);
            this.minWidth = parseFloat(computedStyle.minWidth) || 50;
            this.initialWidth = element.offsetWidth;
            this.elementLeft = element.getBoundingClientRect().left;
            this.$refs.tableContainer.style.userSelect = 'none';
            document.addEventListener('mousemove', this.handleMouseMove);
            document.addEventListener('mouseup', this.stopResize);
        },
        handleMouseMove(event) {
            if (!this.resizing) return;
            const dx = event.clientX - this.initialX;
            let newWidth = this.initialWidth + dx;

            // Prevent the width from snapping to the minimum width
            if (newWidth < this.minWidth) {
                newWidth = this.minWidth;
            }
            this.$set(this.childHeaders[this.currentIndex], 'width', `${newWidth}px`);
        },
        stopResize() {
            if (!this.resizing) return;
            this.resizing = false;
            this.$refs.tableContainer.style.userSelect = '';
            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.stopResize);
            this.updateLayout();
        },
        updateLayout() {
            this.$emit("updateLayout", JSON.parse(JSON.stringify(this.childHeaders)));
        },
        otherActions(headerValue, item) {
          if(headerValue == 'orderNo') {
            this.viewShipment(item, 'order')
          }
        },
        setHeaders() {
          if(typeof this.headers === 'string') {
            let headers = JSON.parse(this.headers);
            if(Array.isArray(headers)) {
              this.headers = headers
            }
          }
            if(JSON.stringify(this.childHeaders) != JSON.stringify(this.headers)) {
                this.childHeaders = JSON.parse(JSON.stringify(this.headers));
            }
        },
        getCountryName(countryCode) {
            let country = null
            country = this.$Format.getCountryDetail({countryCode: countryCode.slice(0, 2)})
            if(country) {
                return country.name + ' - '
            } else {
                return ''
            }
        },
        changeSortBy(value, type) {
          this.$emit("changeSortBy", value, type);
        },
        clearSortBy() {
          this.$emit("clearSortBy");
        }
    }
}
</script>

<style scoped>
.no-select {
    user-select: none;
}
.resizeSide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 80%;
    cursor: col-resize;
    background-color: transparent;
    border-radius: 4px;
    z-index: 200;
}
.resizeSide:hover {
    background-color: var(--v-greyBase-lighten2);
}

.tableHeaderCell {
    contain: content;
    cursor: grab;
    overflow-x: hidden;
    align-items: start;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid var(--v-greyRaised-lighten2) !important;
}
.tableCell {
    contain: content;
    cursor: pointer;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid #1414147b !important;
}
.menuList .v-list-item{
  font-size: 12px;
  min-height: 30px;
  padding: 0;
}
.menuList ::v-deep .v-icon {
  color: #989898 !important;
}

.cellContentLeft {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px
}
.cellContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableHeaderCell:hover, .tableCell:hover {
    background-color: var(--v-greyRaised-lighten1) !important;
}

.tableHeaderCell:hover .sortButton {
    visibility: visible;
}

.sortButton {
    visibility: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  width: auto;
  height: auto;
  border-radius: 6px;
  font-size: 17px;
}
.tooltip {
  position: absolute;
  top: -39px; 
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: var(--v-greyRaised-lighten1) !important;
  color: white; 
  border-radius: 5px;
  width: max-content;
  height: 30px;
  font-size: 13px;
  text-align: center;
}
.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--v-greyRaised-lighten1) !important
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.context.v-list-item {
    min-height: 28px;
    height: 28px;
    padding: 0 0px !important;
}
.context ::v-deep .v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.context ::v-deep .v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.context ::v-deep .v-list-item__title {
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
}

.context ::v-deep .v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
</style>
