<template>
    <div style="height: 100vh; contain: content; background-color: var(--v-greyRaised-base) !important;">
        <div style="display: flex; width: 100%; contain: content;">
            <div style="width: 100%; display: grid">
                <div class="secondRow">
                    <v-menu bottom offset-y nudge-bottom="10">
                        <template v-slot:activator="{on, attrs}">
                            <v-chip :color="group.firstGroup.name ? '#00ba89' : 'grey'" :style="group.firstGroup.name ? {'background-color': 'rgba(78, 95, 71, 0.36) !important'} : {'opacity': '0.7'}" v-on="on" v-bind="attrs" outlined small dense class="mr-2 px-2">
                                <v-icon class="chipIcon">{{group.firstGroup.icon ?? 'layers'}}</v-icon>
                                Group: {{ group.firstGroup.name ?? 'None'}}
                            </v-chip>
                        </template>
                        <v-card style="background-color: var(--v-greyRaised-lighten1) !important" width="180px">
                            <v-subheader style="height: 30px; font-size: 12px;" class="pl-3"><b>Group By</b></v-subheader>
                            <v-list dense>
                                <v-list-item class="px-3" @click="switchGroups('first', null, 'remove')">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon">highlight_off</v-icon>None
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.firstGroup == {}">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item  class="px-3" @click="switchGroups('first', group.firstGroup)" v-if="group.firstGroup.name">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{group.firstGroup.icon ?? 'layers'}}</v-icon>{{ group.firstGroup.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-for="(category, index) in groupCategoriesRemaining" :key="index" class="px-3" @click="switchGroups('first', groupCategoriesRemaining[index])">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{category.icon ?? 'layers'}}</v-icon>{{ category.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.firstGroup.name == category.name">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                    <v-menu bottom offset-y nudge-bottom="10" v-if="group.firstGroup && group.firstGroup.name">
                        <template v-slot:activator="{on, attrs}">
                            <v-chip :color="group.secondGroup.name ? '#00ba89' : 'grey'" :style="group.secondGroup.name ? {'background-color': 'rgba(78, 95, 71, 0.36) !important'} : {'opacity': '0.7'}" v-on="on" v-bind="attrs" outlined small dense class="mr-2 px-2">
                                <v-icon class="chipIcon">{{group.secondGroup.icon ?? 'layers'}}</v-icon>
                                Sub-Group: {{ group.secondGroup.name }}
                            </v-chip>
                        </template>
                        <v-card style="background-color: var(--v-greyRaised-lighten1) !important" width="180px">
                            <v-subheader style="height: 30px; font-size: 12px;" class="pl-3"><b>Group By</b></v-subheader>
                            <v-list dense>
                                <v-list-item class="px-3" @click="switchGroups('second', null, 'remove')">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon">highlight_off</v-icon>None
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.secondGroup == {}">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item  class="px-3" @click="switchGroups('second', group.secondGroup)" v-if="group.secondGroup.name">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{group.secondGroup.icon ?? 'layers'}}</v-icon>{{ group.secondGroup.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-for="(category, index) in groupCategoriesRemaining" :key="index" class="px-3" @click="switchGroups('second', groupCategoriesRemaining[index])">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{category.icon ?? 'layers'}}</v-icon>{{ category.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.secondGroup.name == category.name">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                    <v-menu bottom offset-y nudge-bottom="10" v-if="group.secondGroup.name">
                        <template v-slot:activator="{on, attrs}">
                            <v-chip :color="group.thirdGroup.name ? '#00ba89' : 'grey'" :style="group.thirdGroup.name ? {'background-color': 'rgba(78, 95, 71, 0.36) !important'} : {'opacity': '0.7'}" v-on="on" v-bind="attrs" outlined small dense class="mr-2 px-2">
                                <v-icon class="chipIcon">{{group.thirdGroup.icon ?? 'layers'}}</v-icon>
                                Sub-Group: {{ group.thirdGroup.name }}
                            </v-chip>
                        </template>
                        <v-card style="background-color: var(--v-greyRaised-lighten1) !important" width="180px">
                            <v-subheader style="height: 30px; font-size: 12px;" class="pl-3"><b>Group By</b></v-subheader>
                            <v-list dense>
                                <v-list-item class="px-3" @click="switchGroups('third', null, 'remove')">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon">highlight_off</v-icon>None
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.thirdGroup == {}">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item  class="px-3" @click="switchGroups('third', group.thirdGroup)" v-if="group.thirdGroup.name">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{group.thirdGroup.icon ?? 'layers'}}</v-icon>{{ group.thirdGroup.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-for="(category, index) in groupCategoriesRemaining" :key="index" class="px-3" @click="switchGroups('third', groupCategoriesRemaining[index])">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon class="listIcon"> {{category.icon ?? 'layers'}}</v-icon>{{ category.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon color="primary" small style="font-weight: 600; font-size: 20px;" v-if="group.thirdGroup.name == category.name">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                    <v-chip outlined small dense class="mr-2 px-2" @click="expandCollapse()" v-if="group.firstGroup.value"><v-icon class="chipIcon" >subdirectory_arrow_right</v-icon>{{ collapsed ? 'Expand All' : 'Collapse All' }}</v-chip>
                    <v-divider vertical inset class="mr-2"></v-divider>
                    <v-chip outlined small dense class="px-2" color="redPop" @click="resetFilters" v-if="Object.values(filter).some(x => x.length > 0)"><v-icon class="chipIcon" color="redPop" >cancel</v-icon><span style="color: var(--v-primaryText-base);">Clear Filters</span></v-chip>
                    <v-chip outlined small dense class="px-2">Pallets:<b class="ml-1">{{ distinctCount.length ?? 0 }}</b></v-chip>
                    <v-spacer></v-spacer>
                    <span class="mr-3 text"><b>{{ currentWeek ?? '' }}</b></span>
                    <div style="width: 14vw;">
                        <v-text-field v-model="search" outlined dense hide-details placeholder="Search..." class="searchBar"></v-text-field>
                    </div>
                </div>
                <div @wheel="handleScroll"  class="backgroundMain">
                    <div v-if="loading && group.firstGroup.name" style="width: 100%; height: 100vh">
                        <v-row>
                            <v-col cols="4" class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row class="pl-8">
                            <v-col cols="5"  class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="7" class="py-0"></v-col>
                            <v-col cols="12" class="pl-7">
                                <v-skeleton-loader style="display: flex;" type="heading@12" ></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12"  class="d-flex pl-6 py-0">
                                <div v-for="i in 12" :key="i" class="mx-8">
                                    <v-skeleton-loader type="table-cell, table-cell, table-cell, table-cell, table-cell, table-cell" ></v-skeleton-loader>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row class="pl-8">
                            <v-col cols="5"  class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="7" class="py-0"></v-col>
                            <v-col cols="12" class="pl-7">
                                <v-skeleton-loader style="display: flex;" type="heading@12" ></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12"  class="d-flex pl-6 py-0">
                                <div v-for="i in 12" :key="i" class="mx-8">
                                    <v-skeleton-loader type="table-cell, table-cell" ></v-skeleton-loader>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row class="pl-8">
                            <v-col cols="5"  class="py-0">
                                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="7" class="py-0"></v-col>
                            <v-col cols="12" class="pl-7">
                                <v-skeleton-loader style="display: flex;" type="heading@12" ></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12"  class="d-flex pl-6 py-0">
                                <div v-for="i in 12" :key="i" class="mx-8">
                                    <v-skeleton-loader type="table-cell, table-cell" ></v-skeleton-loader>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else-if="loading && !group.firstGroup.name" style="width: 100%; height: 100vh;">
                        <v-row>
                            <v-col cols="12" class="pl-7">
                                <v-skeleton-loader style="display: flex;" type="heading@12" ></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12"  class="d-flex pl-6 py-0">
                                <div v-for="i in 12" :key="i" class="mx-6">
                                    <v-skeleton-loader type="table-cell@19" ></v-skeleton-loader>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else-if="!loading" class="table" ref="tableContainer" style="contain: content; overflow-x: scroll ;">
                        <div :style="{ width: tableWidth }" class="table-container">
                            <table :width="'100%'">
                                <tr class="mx-0 px-0" style="width: 100%; height: 100%;">
                                    <draggable
                                    v-model="localHeaders"
                                    handle=".handle"
                                    tag="tr"
                                    class="d-flex"
                                    :style="{ cursor: columnDrag ? 'grabbing' : 'grab'}"
                                    :animation="200"
                                    @change="updateLayout()"
                                    ghost-class="ghost-card"
                                    @start="columnDrag = true"
                                    @end="columnDrag = false"
                                    :disabled="resizing"
                                  >
                                    <td class="mx-0 tableHeaderCell" style="max-width: 300px !important;" :style="header.minWidth ? {'min-width' : header.minWidth} : '50px'"  v-for="(header, index) in localHeaders.filter(x => x.show)" :key="index" :width="header.width ?? 'auto'">
                                        <div class="d-flex align-start handle pl-2 py-1" style="height: 100%; width: calc(100% - 5px); flex-wrap: wrap;">
                                            <span style="font-size: 12px; opacity: 0.7; margin-right: 5px;">{{ header.text }}</span>
                                            <v-menu right offset-x v-if="!header.disableSort">
                                              <template v-slot:activator="{ on: menuOn, attrs}">
                                                <v-tooltip small top class="pa-0">
                                                    <template v-slot:activator="{ on: tooltipOn}">
                                                        <v-chip v-on="{ ...tooltipOn, ...menuOn }" v-bind="attrs" v-if="!header.disableSort" :class="isSorted(header.value) ? 'px-1' : 'px-0'" :outlined="isSorted(header.value) ? false : true"  x-small :style="isSorted(header.value) ? '' : {'border' : 'none'}" :color="isSorted(header.value) ? 'tertiary' : ''">
                                                            <v-icon v-if="isSorted(header.value) == false" small color="grey" class="sortButton">unfold_more</v-icon>
                                                            <v-icon v-else-if="isSorted(header.value).order === 'desc' ? true : false" x-small >arrow_upward</v-icon>
                                                            <v-icon v-else x-small>arrow_downward</v-icon>
                                                            <span>{{ isSorted(header.value) ? isSorted(header.value).index + 1 : '' }}</span>
                                                        </v-chip>
                                                    </template>
                                                    Sort
                                                </v-tooltip>
                                              </template>
                                              <v-card style="background: var(--v-greyRaised-lighten1) !important;" class="px-2">
                                                <v-list dense style="background: transparent;" class="menuList">
                                                  <v-list-item @click="changeSort(header.value, 'asc')"><v-icon left small>south</v-icon>Sort Ascending</v-list-item>
                                                  <v-list-item @click="changeSort(header.value, 'desc')"><v-icon left small>north</v-icon>Sort Descending</v-list-item>
                                                  <v-list-item @click="changeSort(header.value, 'remove')" v-if="isSorted(header.value)"><v-icon left small>clear</v-icon>Remove Sort</v-list-item>
                                                  <v-list-item @click="clearSort()" v-if="sortCriteria.length > 1"><v-icon left small>cancel</v-icon>Clear All Sort</v-list-item>
                                                </v-list>
                                              </v-card>
                                            </v-menu>
                                              <v-menu offset-y nudge-right="130" left :close-on-content-click="false" v-if="!header.disableFilter">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-chip v-bind="attrs" v-on="on" class="px-1" x-small :outlined="filter[header.value].length > 0 ? false : true" :style="filter[header.value].length > 0 ? '' : {'border' : 'none'}"  v-if="!header.disableFilter" @click="consoleThis(header)" :color="filter[header.value].length > 0 ? 'edit' : ''">
                                                    <v-icon x-small v-if="filter[header.value].length > 0" >filter_alt</v-icon>
                                                    <v-icon x-small color="grey" v-if="filter[header.value].length == 0" class="sortButton">filter_alt</v-icon>
                                                    <span v-if="filter[header.value].length > 0">{{ filter[header.value].length ?? '' }}</span>
                                                  </v-chip>
                                                </template>
                                                <v-card style=" background: var( --v-modal-base ) !important; border-radius: 20px;">
                                                  <v-card-text>
                                                    <v-list dense style=" background: var( --v-modal-base ) !important; border-radius: 20px;">
                                                      <v-text-field hide-details rounded dense outlined clearable prepend-inner-icon="search" placeholder="Search" autofocus v-model="searchHeaders[header.value]" @change="searchKey++" />
                                                      <v-list dense style=" max-height: 50vh; overflow-y: auto; background: var( --v-modal-base ) !important; " :key="searchKey" >
                                                        <v-list-item v-for="( item, index ) in headerOptions[header.value]" :key="index" >
                                                          <v-list-item-action class="pr-0">
                                                            <v-checkbox v-model="filter[header.value]" :value="item.value" hide-details dense @change="groupData()" ></v-checkbox>
                                                          </v-list-item-action>
                                                          <v-list-item-content>
                                                            <v-list-item-title>
                                                              {{ item.name }}
                                                            </v-list-item-title>
                                                          </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item v-if=" headerOptions[header.value] .length == 0 " >
                                                          <v-list-item-content class="text-center" >
                                                            <span style="font-size: 12px" >No results found</span >
                                                          </v-list-item-content>
                                                        </v-list-item>
                                                      </v-list>
                                                    </v-list>
                                                  </v-card-text>
                                                </v-card>
                                              </v-menu>
                                        </div>
                                        <div :id="`resize-${index}`" class="resizeSide" @mousedown="initiateResize($event, index, header.value)"></div>
                                    </td>
                                  </draggable>
                                </tr>
                            </table>
                        </div>
                        <div v-if="group.firstGroup.name" style="contain: content; overflow-y: scroll" :style="heightMultiplier ? {'height': `calc((100vh - 36px - 30px) * ${heightMultiplier})`, width: tableWidth} : {'height': `calc(100vh - 36px - 30px)`, width: tableWidth} ">
                            <div v-for="(primary, index) in dataGrouped" :key="index">
                                <div class="groupBar">
                                    <v-btn x-small plain icon class="mr-2" @click="expandGroupChange(`primary-${index}`)"><v-icon>{{collapsedGroups.includes(`primary-${index}`) ? 'navigate_next' : 'expand_more'}}</v-icon></v-btn>
                                    <v-chip label small filled v-if="!customHeaders">
                                    {{ primary[group.firstGroup.value] ?? ''}}
                                    </v-chip>
                                    <StockHeaders v-if="(type == 'stock' || type == 'Stock') && customHeaders"/>
                                </div>
                                <div class="expandGroup" :id="`primary-${index}`" :style="collapsedGroups.includes(`primary-${index}` ? {'display' : 'none'} : '')" style="height: 100%;">
                                    <v-lazy :key="index" :options="{threshold: 0.25}" :min-height="24" transition="" v-if="primary && primary.items && primary.items.length > 0 && !group.secondGroup?.name">
                                        <StockTable @addToFilter="addToFilter" ref="childTables" v-if="(type == 'stock' || type == 'Stock') && (!group.secondGroup.name && !group.thirdGroup.name)" :selectedPallets="selectedPallets" @updateSelectedPallets="updateSelectedPallets" :headers="checkHeaders(localHeaders)" :data="primary.items" :key="primaryKey"/>
                                    </v-lazy>
                                    <div v-if="primary && primary.items && primary.items.length > 0 && group.secondGroup?.name">
                                        <div v-for="(secondary, idx) in primary.items" :key="idx">
                                            <div class="groupBar secondaryGroup pl-5">
                                                <v-btn x-small plain icon class="mr-2" @click="expandGroupChange(`secondary-${index}-${idx}`)"><v-icon>{{collapsedGroups.includes(`secondary-${index}-${idx}`) ? 'navigate_next' : 'expand_more'}}</v-icon></v-btn>
                                                <v-chip label small filled v-if="!customHeaders">
                                                    {{ secondary[group.secondGroup.value] ?? ''}}
                                                </v-chip>
                                                <StockHeaders v-else-if="(type == 'stock' || type == 'Stock') && customHeaders"/>
                                            </div>
                                            <div class="expandGroup" :id="`secondary-${index}-${idx}`" :style="collapsedGroups.includes(`secondary-${index}-${idx}` ? {'display' : 'none'} : '')">
                                                <v-lazy :key="idx" :options="{threshold: 0.25}" :min-height="24" transition="" v-if="secondary && secondary.items && secondary.items.length > 0 && !group.thirdGroup?.name">
                                                    <StockTable @addToFilter="addToFilter" class="secondaryGroup" ref="childTables" v-if="(type == 'stock' || type == 'Stock') && (!group.thirdGroup.name)" @updateSelectedPallets="updateSelectedPallets" :selectedPallets="selectedPallets" :data="secondary.items" :headers="checkHeaders(localHeaders)" :key="secondaryKey" />
                                                </v-lazy>
                                                <div v-if="secondary && secondary.items && secondary.items.length > 0 && group.thirdGroup?.name">
                                                    <div v-for="(tertiary, idx2) in secondary.items" :key="idx2">
                                                        <div class="groupBar tertiaryGroup pl-10">
                                                            <v-btn x-small plain icon class="mr-2" @click="expandGroupChange(`tertiary-${index}-${idx}-${idx2}`)"><v-icon>{{collapsedGroups.includes(`tertiary-${index}-${idx}-${idx2}`) ? 'navigate_next' : 'expand_more'}}</v-icon></v-btn>
                                                            <v-chip label small filled v-if="!customHeaders">
                                                                {{ tertiary[group.thirdGroup.value] ?? ''}}
                                                            </v-chip>
                                                            <StockHeaders v-else-if="(type == 'stock' || type == 'Stock') && customHeaders"/>
                                                        </div>
                                                        <div class="expandGroup" :id="`tertiary-${index}-${idx}-${idx2}`" :style="collapsedGroups.includes(`tertiary-${index}-${idx}-${idx2}` ? {'display' : 'none'} : '')">
                                                            <v-lazy :key="idx2" :options="{threshold: 0.25}" :min-height="24" transition="">
                                                                <StockTable @addToFilter="addToFilter" v-if="type == 'stock' || type == 'Stock'" class="tertiaryGroup" ref="childTables" @updateSelectedPallets="updateSelectedPallets" :data="tertiary.items" :selectedPallets="selectedPallets" :headers="checkHeaders(localHeaders)" :key="tertiaryKey" />
                                                            </v-lazy>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!group.firstGroup.name" :style="{ width: tableWidth }" style="contain: content;height: auto;" :key="filterKey">
                            <StockTable @addToFilter="addToFilter" ref="childTables" @updateSelectedPallets="updateSelectedPallets" :headers="checkHeaders(localHeaders)" :data="flatData" :selectedPallets="selectedPallets" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment';
import draggable from "vuedraggable";
import StockHeaders from './Stock/StockHeaders.vue';
import StockTable from './Stock/StockTable.vue';
export default {
    components: {
        StockHeaders,
        StockTable,
        draggable
    },
    props: ['headers', 'data', 'groupCategories', 'type', 'loading', 'customHeaders', 'selectedPallets', 'heightMultiplier', 'simpleFilters'],
    data: () => ({
        group: {
            firstGroup: {},
            secondGroup: {},
            thirdGroup: {},
        },
        collapsed: true,
        collapsedGroups: [],
        dataGrouped: [],
        localHeaders: [],
        currentWeek: null,
        search: '',
        baseKey: 122,
        columnDrag: false,
        searchKey: 0,
        resizing: false,
        initialX: 0,
        initialWidth: 0,
        currentIndex: null,
        flatData: [],
        distinctCount: 0,
        minWidth: 50,
        sortCriteria: [],
        primaryKey: 123,
        secondaryKey: 456,
        tertiaryKey: 789,
        searchTimeout: undefined,
        searchHeaders: {},
        headerOptions: {},
        filter: {},
        filterKey: 22,
    }),
    created() {
        if(sessionStorage.getItem(`group-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`)) {
            this.group = JSON.parse(sessionStorage.getItem(`group-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
            this.groupData()
        }
    },
    mounted() {
        this.setCurrentWeek()
    },
    watch: {
        data: {
            immediate: true,
            handler() {
                this.groupData()
                this.setHeaderOptions()
            },
        },
        search: {
            handler() {
                if(this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.groupData()
                }, 500)
            }
        },
        headers: {
            immediate: true,
            handler() {
                console.log(this.headers)
                if(this.type) {
                    let localHeaders = JSON.parse(localStorage.getItem(`userheaders-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
                    if(localHeaders) {
                        this.localHeaders = localHeaders
                    } else {
                        this.localHeaders = JSON.parse(JSON.stringify(this.headers))
                    }
                    let sortCriteria = JSON.parse(localStorage.getItem(`sortCriteria-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
                    if(sortCriteria) {
                        this.sortCriteria = sortCriteria
                    }
                    let filters = JSON.parse(localStorage.getItem(`filters-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
                    if(filters) {
                        console.log(filters)
                        this.filter = filters
                    }
                    console.log('no')
                    this.setFilter()
                } else {
                    this.localHeaders = JSON.parse(JSON.stringify(this.headers))
                    let sortCriteria = JSON.parse(localStorage.getItem(`sortCriteria-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
                    if(sortCriteria) {
                        this.sortCriteria = sortCriteria
                    }
                    let filters = JSON.parse(localStorage.getItem(`filters-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`))
                    if(filters) {
                        this.filter = filters
                    }
                    this.setFilter()
                }
            }
        }
    },
    computed: {
        groupCategoriesRemaining() {
            let remaining = []
            remaining = this.groupCategories
            if(this.group.firstGroup.name) {
                remaining = remaining.filter(x => x.name != this.group.firstGroup.name)
            }
            if(this.group.secondGroup.name) {
                remaining = remaining.filter(x => x.name != this.group.secondGroup.name)
            }
            if(this.group.thirdGroup.name) {
                remaining = remaining.filter(x => x.name != this.group.thirdGroup.name)
            }
            return remaining
        },

        tableWidth() {
            let result = 0;
            this.localHeaders.filter(x => x.show).forEach((x) => {
                result += parseInt(x.width ? x.width.replace("px", "") : 150);
            });
            return result + "px";
        },
    },
    methods: {
        consoleThis(val) {
            val = JSON.parse(JSON.stringify(val))
            console.log(val)
        },
        isSorted(value) {
          if(this.sortCriteria.find(x => x.value == value)) {
            let find = this.sortCriteria.find(x => x.value == value);
            let index = this.sortCriteria.findIndex(x => x.value == value);
            let obj = {
              value: find.value,
              order: find.order,
              index: index
            }
            return obj;
          } else {
            return false
          }
        },
        addToFilter(value, header) {
            console.log(value, header)
            if(this.simpleFilters) {
                if (this.filter[header].includes(value)) {
                    this.filter[header] = this.filter[header].filter((x) => x != value);
                } else {
                    this.filter[header].push(value);
                }
                this.filterKey++;
                this.groupData();
            }
        },
        resetFilters() {
          let wait = this.setFilter();
          if(wait) {
              this.groupData();
            }
        },
        setFilter() {
          this.setHeaderOptions()
          let values = this.localHeaders.map((x) => x.value);
          this.filter = {};
          values.forEach((x) => {
            this.searchHeaders[x] = null;
            this.filter[x] = [];
          });
          return true
        },
        setHeaderOptions() {
          for(let i = 0; i < this.localHeaders.length; i++){
            let value = this.localHeaders[i].value;
            let result = [...new Set(this.data.filter((x) => x[value]).map((x) => ({ name: x[value], value: x[value] })))];
            let uniqueValues = [...new Set(result.map((x) => x.value))];
            result = uniqueValues.map((x) => result.find((y) => y.value === x));
            this.headerOptions[this.localHeaders[i].value] = result;
          }
          console.log(this.headerOptions)
        },
        itemSelected(id) {
          let result = this.selectedPallets.includes(id);
          return result;
        },
        checkHeaders(headers){
            if(Array.isArray(headers)) {
                return headers
            } else if(typeof headers === 'string') {
                let parsed = JSON.parse(headers)
                return parsed
            } else {
                return []
            }
        },
        updateSelectedPallets(pallet) {
            this.$emit('updateSelectedPallets', pallet)
        },
        updateLayout() {
            localStorage.setItem(`userheaders-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`, JSON.stringify(this.localHeaders))
        },
        switchGroups(groupIndex, category, remove) {
            if(groupIndex == 'first') {
                if(remove) {
                    let first = this.localHeaders.find(x => x.value == this.group.firstGroup.value)
                    if(first) {
                        first.show = true
                    }
                    this.group.firstGroup = {}
                    let second = this.localHeaders.find(x => x.value == this.group.secondGroup.value)
                    if(second) {
                        second.show = true
                    }
                    this.group.secondGroup = {}
                    let third = this.localHeaders.find(x => x.value == this.group.thirdGroup.value)
                    if(third) {
                        third.show = true
                    }
                    this.group.thirdGroup = {}
                } else {
                    this.group.firstGroup = category
                }
            } else if(groupIndex == 'second') {
                if(remove) {
                    let second = this.localHeaders.find(x => x.value == this.group.secondGroup.value)
                    if(second) {
                        second.show = true
                    }
                    this.group.secondGroup = {}
                    let third = this.localHeaders.find(x => x.value == this.group.thirdGroup.value)
                    if(third) {
                        third.show = true
                    }
                    this.group.thirdGroup = {}
                } else {
                    this.group.secondGroup = category
                }
            } else if(groupIndex == 'third') {
                if(remove) {
                    let third = this.localHeaders.find(x => x.value == this.group.thirdGroup.value)
                    if(third) {
                        third.show = true
                    }
                    this.group.thirdGroup = {}
                } else {
                    this.group.thirdGroup = category
                }
            }
            if(this.type){
                sessionStorage.setItem(`group-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`, JSON.stringify(this.group))
            }
            if(this.group.firstGroup.name) {
                this.groupData()
            }
        },
        async expandCollapse() {
            let allGroupKeys = []; 
            if(this.collapsed) {
                this.collapsed = false;
                this.collapsedGroups.forEach((group) => {
                    this.expandGroupChange(group)
                })
            } else if(!this.collapsed){
                this.collapsed = true;
                if(this.group.firstGroup.value && this.dataGrouped.length > 0) {
                    this.dataGrouped.forEach((x, index) => {
                        allGroupKeys.push(`primary-${index}`)
                        if(this.group.secondGroup.value) {
                            x.items.forEach((y, idx) => {
                                allGroupKeys.push(`secondary-${index}-${idx}`)
                                if(this.group.thirdGroup.value) {
                                    y.items.forEach((z, idx2) => {
                                        allGroupKeys.push(`tertiary-${index}-${idx}-${idx2}`)
                                    })
                                }
                            })
                        }
                    })
                }
            }
            if(allGroupKeys.length > 0) {
                allGroupKeys.forEach((group) => {
                    this.expandGroupChange(group)
                })
            }
        },
        dataFiltered() {
            let data = []
            if(this.data.length > 0) {
                data = this.data
                if(this.search) {
                    let keys = this.localHeaders.map(x => x.value)
                    let filtered = this.data.filter(x => {
                        let found = false
                        keys.forEach(key => {
                            if(x[key] && x[key].toString().toLowerCase().includes(this.search.toLowerCase())) {
                                found = true
                            }
                        })
                        return found
                    })
                    data = filtered
                }
                if(this.simpleFilters) {
                    console.log(this.simpleFilters)
                    let appliedfilter = Object.keys(this.filter).filter((x) => this.filter[x].length > 0);
                    console.log(appliedfilter)
                    for (let i = 0; i < appliedfilter.length; i++) {
                        data = data.filter((x) => this.filter[appliedfilter[i]].includes(x[appliedfilter[i]]));
                    }
                }
                if(this.sortCriteria.length > 0) {
                    data = this.multiSort(data, this.sortCriteria)
                }
            }
            this.distinctCount = [...new Set(data.map(x => x.sscc))]
            console.log(this.distinctCount)
            return data
        },
        async computeGrouped() {
            const groupOrder = [];
            console.log(this.group)
            if (this.group.firstGroup && this.group.firstGroup.value) {
                groupOrder.push(this.group.firstGroup.value)
                if (this.group.secondGroup && this.group.secondGroup.value) {
                    groupOrder.push(this.group.secondGroup.value)
                    if (this.group.thirdGroup && this.group.thirdGroup.value) {
                        groupOrder.push(this.group.thirdGroup.value)
                    }
                }
            }
            function groupBy(array, key) {
              return array.reduce((result, item) => {
                const groupValue = item[key];
                if (!result[groupValue]) {
                  result[groupValue] = { items: [] };
                }
                result[groupValue].items.push(item);
                return result;
              }, {});
            }
            function nestGroups(array, keys) {
              if (keys.length === 0) {
                return array.map((item, index) => ({
                    ...item,
                    index: index
                }));
              }
              const key = keys[0];
              const grouped = groupBy(array, key);
              return Object.entries(grouped).map(([keyValue, group]) => ({
                [key]: keyValue,
                ...group,
                items: nestGroups(group.items, keys.slice(1))
              }));
            }
            let blocks = []
            let dataGrouped = JSON.parse(JSON.stringify(this.data))
            if(this.simpleFilters) {
                let appliedfilter = Object.keys(this.filter).filter((x) => this.filter[x].length > 0);
                for (let i = 0; i < appliedfilter.length; i++) {
                    dataGrouped = dataGrouped.filter((x) => this.filter[appliedfilter[i]].includes(x[appliedfilter[i]]));
                }
            }
            if(this.sortCriteria.length > 0) {
                dataGrouped = this.multiSort(dataGrouped, this.sortCriteria)
            } 
            if(this.search != '' && this.search.length > 0) {
                let keys = this.localHeaders.map(x => x.value)
                console.log(keys)
                console.log(this.search)
                dataGrouped = dataGrouped.filter(x => {
                    let found = false
                    keys.forEach(key => {
                        if(x[key] && x[key].toString().toLowerCase().includes(this.search.toLowerCase())) {
                            found = true
                        }
                    })
                    return found
                })
            }
            blocks = nestGroups(dataGrouped, groupOrder);
            return blocks
        },
        async groupData() {
            let groupedData = []
            if(this.data.length == 0) {
                console.log('No data to group')
                return
            }
            if(this.group.firstGroup.name) {
                let computed = await this.computeGrouped()
                JSON.parse(JSON.stringify(computed))
                groupedData = computed
                this.collapsed = false;
                this.dataGrouped = groupedData
            }
                let data = await this.dataFiltered()
                this.flatData = data
            if(this.type) {
                localStorage.setItem(`filters-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`, JSON.stringify(this.filter))
            }
        },
        multiSort(array, criteria) {
          return array.slice().sort((a, b) => {
            for (let i = 0; i < criteria.length; i++) {
              const { value, order } = criteria[i];
              const result = this.compare(a[value], b[value]);
              if (result !== 0) {
                return order === 'asc' ? result : -result;
              }
            }
            return 0;
          });
        },
        compare(a, b) {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        },
        changeSort(header, type) {
            const existing = this.sortCriteria.find(x => x.value == header)
            if(!type) {
                if(existing) {
                    if(existing.order == 'asc') {
                        existing.order = 'desc'
                    } else {
                        this.sortCriteria = this.sortCriteria.filter(x => x.value != header)
                    }
                } else {
                    this.sortCriteria.push({ value: header, order: 'asc' });
                }
            } else {
                if(existing) {
                    type != 'remove' ? existing.order = type : this.sortCriteria = this.sortCriteria.filter(x => x.value != header)
                } else {
                    this.sortCriteria.push({ value: header, order: type });
                }
            }
            if(this.type) {
                localStorage.setItem(`sortCriteria-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`, JSON.stringify(this.sortCriteria))
            }
            if(this.group.firstGroup.name) {
                this.groupData()
            }
        },
        clearSort() {
            this.sortCriteria = []
            if(this.type) {
                localStorage.removeItem(`sortCriteria-${this.type}-${this.$store.state.currentOrg.id}-${this.$store.state.user.id}`)
            }
            if(this.group.firstGroup.name) {
                this.groupData()
            }
        },
        expandGroupChange(id) {
            let element = document.getElementById(id);
            if (element) {
                if (element.style.display === "none") {
                    element.style.display = "block";
                    this.collapsedGroups = this.collapsedGroups.filter(x => x !== id);
                } else {
                    element.style.display = "none";
                    this.collapsedGroups.push(id);
                }
            } else {
                console.warn(`Element with id ${id} not found`);
            }
        },
        handleScroll(event) {
            if(this.group.firstGroup.name) {
                if (event.shiftKey) {
                    const delta = event.deltaY;
                    this.$refs.childTables.forEach((childTable) => {
                        if (childTable && childTable.$el) {
                            childTable.$el.scrollLeft += delta;
                        }
                    });
                }
            }
        },
        setCurrentWeek() {
            let currentWeek = moment().isoWeek();
            let currentYear = moment().isoWeekYear();
            this.currentWeek = `${currentYear} - W${currentWeek}`
        },
        initiateResize(event, index) {
          this.resizing = true;
          this.initialX = event.clientX;
          this.currentIndex = index;
          const element = event.target.parentElement;
          const computedStyle = window.getComputedStyle(element);
          this.minWidth = parseFloat(computedStyle.minWidth) || 50;
          this.initialWidth = element.offsetWidth;
        //   this.elementLeft = element.getBoundingClientRect().left;
          this.$refs.tableContainer.style.userSelect = 'none';
          document.addEventListener('mousemove', this.handleMouseMove);
          document.addEventListener('mouseup', this.stopResize);
      },
      handleMouseMove(event) {
          if (!this.resizing) return;
          const dx = event.clientX - this.initialX;
          let newWidth = this.initialWidth + dx;
          
          // Prevent the width from snapping to the minimum width
          if (newWidth < this.minWidth) {
              newWidth = this.minWidth;
          }
          this.$set(this.localHeaders[this.currentIndex], 'width', `${newWidth}px`);
      },
      stopResize() {
          if (!this.resizing) return;
          this.resizing = false;
          this.$refs.tableContainer.style.userSelect = '';
          document.removeEventListener('mousemove', this.handleMouseMove);
          document.removeEventListener('mouseup', this.stopResize);
          this.updateLayout();
      },
    }
}
</script>

<style scoped>
.chipIcon {
    margin-right: 3px;
    font-size: 16px !important;
}
.text {
    font-size: 12px;
}
.listIcon {
    margin-right: 10px;
    font-size: 16px !important;
}
.backgroundMain {
    background-color: var(--v-greyRaised-base) !important;
    width: 100%;
    contain: content;
    overflow-y: scroll;
    overflow-x: auto;
    height: 100%;
}
.secondRow {
    display: flex;
    width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 72px;
    padding: 0 5px;
    align-items: center;
    background-color: var(--v-greyRaised-base) !important;
}
.searchBar ::v-deep .v-input__slot {
    height: 32px !important;
    min-height: 32px !important;
}
.v-skeleton-loader.theme--dark ::v-deep .v-skeleton-loader__table-heading {
    background: var(--v-greyRaised-base) !important;
}
.resizeSide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 80%;
    cursor: col-resize;
    background-color: transparent;
    border-radius: 4px;
    z-index: 200;
}
.resizeSide:hover {
    background-color: var(--v-greyBase-lighten2);
}
.tableHeaderCell {
    contain: content;
    cursor: grab;
    overflow-x: hidden;
    align-items: start;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid var(--v-greyRaised-lighten2) !important;
}
.tableCell {
    contain: content;
    cursor: pointer;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid #1414147b !important;
}
.cellContentLeft {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px
}
.cellContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableHeaderCell:hover, .tableCell:hover {
    background-color: var(--v-greyRaised-lighten1) !important;
}

.tableHeaderCell:hover .sortButton {
    visibility: visible;
}

.sortButton {
    visibility: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.context.v-list-item {
    min-height: 28px;
    height: 28px;
    padding: 0 0px !important;
}
.context ::v-deep .v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.context ::v-deep .v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.context ::v-deep .v-list-item__title {
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
}

.context ::v-deep .v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
</style>